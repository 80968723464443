@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.projects .slick-prev:before {
  font-size: 45px;
  color: #1ABAA9;
  margin-left: -20px;
}
.projects .slick-prev{left:-1%}
.projects .slick-next{right:-1%}
.projects .slick-next:before {
  font-size: 45px;
  color: #1abaa9;
  margin-right: 20px;
}
.dark .projects .slick-prev:before,.dark .projects .slick-next:before{
  color: white;

}
.dark button.bg-gradient-to-r.from-teal-500.to-cyan-500{
background:white;
color:black
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{color:black}
.projects .slick-dots li button:before,.projects .slick-dots li button{
  font-size:15px
 }
 .projects .slick-slider.slick-initialized{
   width:90%;
   background: #1ABAA91a;
      border-radius:12px
 }
 .projects  .slick-dots{margin-bottom:40px}
input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
@media only screen and (max-width: 1024px) {
  .projects .slick-slider.slick-initialized{
    width:90%
  }
  .projects .slick-prev{left:-2%}
.projects .slick-next{right:-2%}
.slick-slide img{width:100%}
.projects .slick-prev:before{margin-left:-15px;font-size: 35px;}
.projects .slick-next:before{margin-right:-15px;font-size: 35px;}
}
[type='checkbox']:checked{
  background-color:  #1abaa9;
  background-image: none;
}
@media only screen and (max-width: 767px) {
.button-container{
  flex-direction: column;
  margin: 0;
}

}