.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.dark-shadow {
  box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35);
}
.bg-icewhite {
  background-color: var(--icewhite);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile-nav {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-gray-900 duration-500;
}
nav li a {
  @apply px-4 py-5 text-lg;
}
nav li a:hover {
    color: #7477FF;
}

.skill-item:hover {
  animation: pulse 2s infinite;
  transform: scale(1);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--box-shadow-color);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #ff6a3d00;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #ff6a3d00;
  }
}


